import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
// Ignoring the linting check because this comes from a Kotlin Library
import { AsyncPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { countriesList, country } from '@features/country/data';
import { navigateTo } from '@presentation/shared/router';
// @ts-ignore
import * as CountryResolver from '@taager-experience-shared/country-resolver';
import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { UserInfoModel } from 'src/app/core/domain/auth/auth.model';
import { BaseCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { SetRegisterTokenUseCase } from 'src/app/core/usecases/auth/set-register-token.usecase';
import { CompleteUserProfileUseCase } from 'src/app/core/usecases/auth/update-user-profile.usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { Scope } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import {
  AUTH_CONSTS,
  CUSTOMER_TOUCH_POINT,
  ONBOARDING_URL,
} from 'src/app/presentation/shared/constants';
import { Country } from 'src/app/presentation/shared/interfaces/countries';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { MixpanelService } from 'src/app/presentation/shared/services/mixpanel.service';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { TreeNode } from 'src/app/presentation/shared/utilities/tree.utility';
import { SharedNotificationConfig } from '../../../shared/notification/shared.notification.component';
import { SignupStepsBaseComponent } from '../signup-steps-base.component';

@Component({
  templateUrl: 'signup-details.component.html',
  styleUrls: ['signup-details.component.scss'],
  providers: [],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatChipsModule,
    MatIconModule,
    MatRadioModule,
    NgClass,
    MatSlideToggleModule,
    NgTemplateOutlet,
    AsyncPipe,
  ],
})
export class SignupDetailsComponent extends SignupStepsBaseComponent implements OnInit, OnDestroy {
  @Input() headerText: string;

  @Input() subHeaderText: string;

  @Input() scope: Scope;

  public storeIsEnabled: false;

  public categories: TreeNode<BaseCategoryModel>[];

  public nationalities: Country[];

  public ageGroups: { displayValue: string; value: string }[] = [
    { displayValue: '18-12', value: '12-18' },
    { displayValue: '24-18', value: '18-24' },
    { displayValue: '30-24', value: '24-30' },
    { displayValue: '+30', value: 'more' },
  ];

  public signupForm: UntypedFormGroup = new UntypedFormGroup({
    fullName: new UntypedFormControl('', [Validators.required]),
    nationality: new UntypedFormControl('', [Validators.required]),
    preferredCategories: new UntypedFormControl('', [Validators.required]),
    gender: new UntypedFormControl('', [Validators.required]),
    age: new UntypedFormControl('', [Validators.required]),
    storeName: new UntypedFormControl(),
  });

  public formIsSubmitted = false;

  private _onDestroy$: Subject<boolean> = new Subject<boolean>();

  public responseMessage: SharedNotificationConfig;

  public preferredCategories$: BehaviorSubject<
    Array<{
      key: string;
      label: string;
    }>
  > = new BehaviorSubject<
    Array<{
      key: string;
      label: string;
    }>
  >([]);

  constructor(
    private _completeUserProfileUseCase: CompleteUserProfileUseCase,
    private _router: Router,
    private mixpanelService: MixpanelService,
    private _setRegisterTokenUseCase: SetRegisterTokenUseCase,
    private _logGTagEventUseCase: LogGTagEventUseCase,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _responsiveService: ResponsiveService,
    private _localStorageService: LocalStorageService,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this.nationalities = CountryResolver.getSupportedNationalities(
      CountryResolver.com.taager.locale.Arabic,
    );

    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (commercialCategoriesTree) => {
        if (commercialCategoriesTree) {
          const categoriesNodes = commercialCategoriesTree.root.children;
          this.categories = categoriesNodes;
        }
      },
    });
    this._listenToPreferredCategoriesChange();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
    if (this.formIsSubmitted === false) {
      this._logMixpanelEventUseCase.execute({
        eventName: 'complete_profile_closed',
        payload: {
          Status: 'close profile before submitting',
        },
      });
    }
  }

  private _listenToPreferredCategoriesChange(): void {
    this.signupForm
      .get('preferredCategories')!
      .valueChanges.pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: (value) => {
          const preferredCategories = this.categories
            .filter((category) => value.includes(category.key))
            .map((category) => ({
              key: category.key,
              label: category.value.name.arabicName,
            }));
          this.preferredCategories$.next(preferredCategories);
        },
      });
  }

  remove(unselected: any): void {
    this.signupForm
      .get('preferredCategories')!
      .setValue(
        this.signupForm.get('preferredCategories')!.value.filter((val: any) => val !== unselected),
      );
  }

  setSliderValue(event: any): void {
    this.storeIsEnabled = event.checked;
    if (!this.storeIsEnabled) {
      this.signupForm.get('storeName')!.reset();
    }
  }

  getSliderValue(): boolean {
    return this.storeIsEnabled;
  }

  getActiveRadioButton(a: any): string {
    return this.signupForm.get(a)!.value;
  }

  public onSubmit(): void {
    this.formIsSubmitted = true;
    let userInfo: UserInfoModel = {
      fullName: this.signupForm.get('fullName')!.value,
      nationality: this.signupForm.get('nationality')!.value,
      preferredCategories: this.signupForm.get('preferredCategories')!.value,
      gender: this.signupForm.get('gender')!.value,
      age: this.signupForm.get('age')!.value,
    };
    if (this.signupForm.get('storeName')!.value) {
      userInfo = {
        ...userInfo,
        storeName: this.signupForm.get('storeName')!.value,
      };
    }
    this._completeUserProfileUseCase
      .execute(userInfo)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        next: (res: any) => {
          this._doGoToNextStep();
          this._logSignupEventToAnalytics(res.taagerId, res.phoneNumber);
          this._setRegisterTokenUseCase.execute(res.jwtToken);
          if (this.scope === 'signup') {
            this.mixpanelService.identify();
            this._logMixpanelEventUseCase.execute({
              eventName: 'sign_up_complete_profile_submitted',
              payload: this.signupForm.value,
            });

            // countries must be loaded before navigating so we can cache them
            countriesList.load().then(() => {
              navigateTo(ONBOARDING_URL);
            });
          } else {
            this._logMixpanelEventUseCase.execute({
              eventName: 'opt_in_complete_profile_submit_click',
              payload: this.signupForm.value,
            });
          }
        },
        error: (err) => {
          if (err.error.errorCode === 'user-data-already-published') {
            this._doGoToNextStep();
          } else {
            this.responseMessage = {
              msg:
                AUTH_CONSTS.REGISTER_ERRORS_MAP.get(err.error.errorCode) ||
                'حدث خطأ ما، من فضلك أعد المحاولة',
              status: 'error',
              iconMeta: {
                icon: 'assets/img/auth/danger-white.svg',
                position: 'before',
              },
            };
            this._logMixpanelEventUseCase.execute({
              eventName: 'complete_profile_error',
              payload: {
                Status: 'error submitted form',
              },
            });
          }
        },
      });
  }

  private async _logSignupEventToAnalytics(merchantId: string, phoneNumber: string): Promise<void> {
    const eventName = 'sign_up';
    const payload = {
      'Taager ID': merchantId,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'Phone Number': phoneNumber,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Channel: this._localStorageService.getStorage(CUSTOMER_TOUCH_POINT),
    };
    this._logGTagEventUseCase.execute({
      eventName,
      payload,
    });
    this._localStorageService.remove(CUSTOMER_TOUCH_POINT);
  }

  private _doGoToNextStep(): void {
    this.goToNextStep$.next(this.nextStep);
  }
}
