<form [formGroup]="OTPFormGroup" class="signup-otp">
  <app-shared-notification-component
    *ngIf="responseMessage"
    [config]="responseMessage"
  ></app-shared-notification-component>
  <ng-container *ngIf="signUpV2Enabled || showEmailOTPonWithdrawal; else oldFlow">
    <div class="signup-otp__instructional-text heading2--bold">
      يرجى توثيق حسابك عبر الخطوات التالية
    </div>
    <div
      class="signup-otp__options"
      *ngFor="let option of otpVerificationOptions"
      [ngClass]="{ 'signup-otp__option_verified': option.isVerified }"
    >
      <div class="signup-otp__options_list">
        <img [src]="option.icon" />
        <div class="signup-otp__options_list_text">
          <p class="body2--bold">{{ option.title }}</p>
          <p class="caption1--medium">{{ option.text }}</p>
        </div>
        <div class="signup-otp__options_list_button_section">
          <button
            *ngIf="!option.isVerified && selectedOTPType !== option.value && !option.isDisabled"
            type="button"
            [disabled]="option.disabled"
            (click)="otpTypeExpand(option.value)"
          >
            توثيق
          </button>
          <span class="ghost" *ngIf="option.isVerified">تم التوثيق</span>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="
          option.value === 'sms' && selectedOTPType === 'sms'
            ? smsOTPTemplate
            : option.value === 'email' && selectedOTPType === 'email'
            ? emailOTPTemplate
            : null
        "
      ></ng-container>
    </div>
  </ng-container>
  <ng-template #oldFlow>
    <ng-container *ngTemplateOutlet="smsOTPTemplate"></ng-container>
  </ng-template>

  <ng-template #smsOTPTemplate>
    <ng-container *ngIf="!signUpV2Enabled || (signUpV2Enabled && selectedOTPType === 'sms')">
      <div [ngClass]="{ 'signup-otp__otp-background': signUpV2Enabled }">
        <div *ngIf="signUpV2Enabled" class="signup-otp__sub-text body2--bold">
          يرجى إدخال الكود المرسل على هاتفك
        </div>
        <div class="signup-otp__phone-number">
          <ng-container
            *ngTemplateOutlet="
              showEditPhoneNumber ? editPhoneNumberTemplate : viewPhoneNumberTemplate
            "
          ></ng-container>

          <ng-template #viewPhoneNumberTemplate>
            <div class="signup-otp__phone-number__view">
              <div class="signup-otp__phone-number__view__number">
                <span class="signup-otp__phone-number__view__number__prefix caption1--medium">
                  +{{ phonePrefix }}
                </span>
                <span class="signup-otp__phone-number__view__number__user-number caption1--medium">
                  {{ userPhoneNumber }}
                </span>
              </div>
              <div (click)="toggleEditPhoneNumber()" class="signup-otp__phone-number__view__icon">
                <img
                  loading="lazy"
                  src="{{ authAssetsRelativePath + 'edit-otp-phone-number.svg' }}"
                  alt="edit-otp-phone-number"
                />
              </div>
            </div>
          </ng-template>

          <ng-template #editPhoneNumberTemplate>
            <div class="signup-otp__phone-number__edit">
              <div
                (click)="confirmPhoneNumberSelection()"
                class="signup-otp__phone-number__edit__check"
              >
                <img
                  loading="lazy"
                  src="{{ authAssetsRelativePath + 'white-check-mark.svg' }}"
                  alt="white-check-mark"
                />
              </div>
              <div class="signup-otp__phone-number__edit__input">
                <app-meta-phone-number-field
                  [userPhoneNumber]="userPhoneNumber"
                  [userCountryCode]="userCountryCode || 'EGY'"
                  [prefixDisabled]="!userCountryCode ? false : true"
                  (result$)="currentUserPhoneNumberSelection($event)"
                ></app-meta-phone-number-field>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="signup-otp__numbers-input">
          <div *ngFor="let otpField of OTPFormFields" class="signup-otp__numbers-input__wrapper">
            <input
              (focus)="onOTPFieldFocus(otpField)"
              (blur)="onOTPFieldBlur(otpField)"
              placeholder="0"
              type="number"
              formControlName="{{ otpField }}"
              (keydown)="onOTPFieldChange($event, otpField)"
              class="body1--medium"
              #otpFieldsTemplates
            />
            <div
              class="signup-otp__numbers-input__wrapper__indicator {{
                OTPFieldsFocusStates[otpField] ? 'active' : 'inactive'
              }}"
            ></div>
          </div>
        </div>
        <div class="signup-otp__code-will-expire-in">
          <ng-container
            *ngTemplateOutlet="showOTPCodeTimer ? OTPCodeTimerTemplate : resendOTPCodeTemplate"
          ></ng-container>
          <ng-template #OTPCodeTimerTemplate>
            <div class="signup-otp__code-will-expire-in__timer__instruction">
              <span class="caption1--medium">ستنتهي صلاحية الكود خلال:</span>
            </div>
            <div class="signup-otp__code-will-expire-in__timer__countdown caption1--medium">
              {{ currentTimeToExpiryInMilliSeconds | countdownTimerPipe : 'min:secs' }}
            </div>
          </ng-template>

          <ng-template #resendOTPCodeTemplate>
            <div
              (click)="requestOTP()"
              class="signup-otp__code-will-expire-in__resend-code body1--medium"
            >
              <div class="signup-otp__code-will-expire-in__resend-code__question caption1--medium">
                لم يصلك الكود؟
              </div>
              <div class="signup-otp__code-will-expire-in__resend-code__action caption1--medium">
                إعادة إرسال الكود
              </div>
            </div>
          </ng-template>
        </div>

        <div class="signup-otp__btn-container" *ngIf="signUpV2Enabled || showEmailOTPonWithdrawal">
          <button
            class="confirmation-btn"
            [ngClass]="{ disabled: OTPFormGroup.invalid }"
            [disabled]="OTPFormGroup.invalid"
            id="phone-confirmation-btn"
            type="button"
            (click)="submitOTP()"
          >
            تأكيد
          </button>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #emailOTPTemplate>
    <div [ngClass]="{ 'signup-otp__otp-background': signUpV2Enabled }">
      <form (ngSubmit)="emailOTPFieldSubmit()" [formGroup]="emailOTPForm">
        <div class="signup-otp__sub-text body2--bold">
          يرجى إدخال الكود المرسل على بريدك الالكتروني
        </div>
        <p class="caption1--regular signup-otp__content-light">{{ userEmail }}</p>
        <div class="flex-container signup-otp__fields">
          <div *ngFor="let input of emailFormInput; index as i">
            <input
              size="10"
              #formRow
              type="text"
              formControlName="{{ input }}"
              class="form-control"
              maxlength="1"
              placeholder="0"
              type="number"
              (keyup)="emailOTPkeyUpEvent($event, i)"
            />
          </div>
        </div>
      </form>
      <div class="signup-otp__code-will-expire-in">
        <ng-container
          *ngTemplateOutlet="
            showEmailOTPCodeTimer ? EmailOTPCodeTimerTemplate : resendEmailOTPCodeTemplate
          "
        ></ng-container>
        <ng-template #EmailOTPCodeTimerTemplate>
          <div class="signup-otp__code-will-expire-in__timer__instruction">
            <span class="caption1--medium">ستنتهي صلاحية الكود خلال:</span>
          </div>
          <div class="signup-otp__code-will-expire-in__timer__countdown caption1--medium">
            {{ currentEmailTimeExpiry | countdownTimerPipe : 'min:secs' }}
          </div>
        </ng-template>

        <ng-template #resendEmailOTPCodeTemplate>
          <div
            (click)="requestEmailOTP()"
            class="signup-otp__code-will-expire-in__resend-code body1--medium"
          >
            <div class="signup-otp__code-will-expire-in__resend-code__question caption1--medium">
              لم يصلك الكود؟
            </div>
            <div class="signup-otp__code-will-expire-in__resend-code__action caption1--medium">
              إعادة إرسال الكود
            </div>
          </div>
        </ng-template>
      </div>
      <div class="signup-otp__btn-container">
        <button
          class="confirmation-btn"
          id="email-confirmation-btn"
          [ngClass]="{ disabled: emailOTPForm.invalid }"
          [disabled]="emailOTPForm.invalid"
          type="button"
          (click)="emailOTPFieldSubmit()"
        >
          تأكيد
        </button>
        <button
          *ngIf="isUserLoggingIn || scope === 'opt-in'"
          class="ghost-btn"
          (click)="redirectToUnaccessibleEmailForm()"
        >
          ليس لدي إمكانية الوصول إلى هذا البريد الالكتروني
        </button>
      </div>
    </div>
  </ng-template>

  <button
    *ngIf="!signUpV2Enabled && !showEmailOTPonWithdrawal"
    [ngClass]="{ disabled: OTPFormGroup.invalid }"
    class="signup-otp__submit-form body1--medium"
    [disabled]="OTPFormGroup.invalid"
    (click)="submitOTP()"
  >
    استمرار
  </button>
  <button
    *ngIf="signUpV2Enabled && isUserLoggingIn"
    class="ghost-btn signup-otp__skip_btn"
    (click)="confirmSkipOTP()"
  >
    سأقوم باستكمال الخطوة في وقت لاحق
  </button>
</form>
